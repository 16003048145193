<template>
    <form ref="form" @submit.prevent="onSubmit">

        <FieldsList :groupFields="groupFields" :errors="errors" @update:fields="getFields" />

        <div class="form-big__submit">
            <div class="btn-wrapper">
                <button
                    class="btn btn-red"
                    type="submit"
                    :disabled="isFilledRequiredInputs"
                >
                Перейти к Шагу 2
                    <svg class="icon icon-arrow-right-bold ">
                        <use xlink:href="#arrow-right-bold"></use>
                    </svg>
                </button>
                <div class="tooltip" v-if="isFilledRequiredInputs">
                    <div class="tooltip__text">Необходимо заполнить все поля для перехода к Шагу 2</div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import store from '@/store/index'
import FieldsList from '@/components/form/fields'

export default {
    name: "Fields",

    components: { FieldsList },

    data() {
        return {
            fields: {},
            errors: {},
        };
    },

    beforeRouteEnter(to, from, next) {
        store.commit('APPLICATION_SET_USER_TYPE', to.meta.type)
        next()
    },

    beforeRouteLeave() {
        this.fields = {},
        this.errors = {}
    },

    computed: {
        groupFields() {
            return this.$store.getters.getUserRegisterFields
        },

        getUserType() {
            return this.$store.getters.getUserType
        },

        isFilledRequiredInputs() {
            let isAllFilled = true
            let inputsCodes = []

            Object.values(this.groupFields)
                .map(group => group.fields.filter(field => field.required).map(field => field.code))
                .forEach(group => inputsCodes = inputsCodes.concat(group))

            inputsCodes.forEach(code => {
                if(typeof this.fields[code] === "undefined" || !this.fields[code]) return isAllFilled = false
            })

            return !isAllFilled
        },
    },

    methods: {
        onSubmit() {
            this.setUserId()
            this.sendRequest()
        },

        sendRequest() {
            store.dispatch('APPLICATION_SEND_FORM', this.fields)
                .then(() => {
                    this.$router.push("/application/courses")

                    this.fields = {}
                    this.errors = {}
                })
                .catch(error => {
                    if (error.response.data.errors) this.errors = error.response.data.errors
                })
        },

        setUserId() {
            this.fields["user_type_id"] = this.getUserType.user_type.id
        },

        getFields(payload) {
            this.fields = payload
        }
    }
}
</script>
